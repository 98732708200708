<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ReceivedInvoiceForm
      :disabled="$store.getters.loading[`put:api/documents/${editedReceivedInvoice.id}`]"
      :errors="receivedInvoiceValidationErrors"
      :received-invoice="editedReceivedInvoice"
      @clear:errors="CLEAR_RECEIVED_INVOICE_VALIDATION_ERRORS"
      @cancel="goToReceivedInvoicesPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ReceivedInvoiceForm from '@/components/forms/ReceivedInvoiceForm';

export default {
  name: 'EditReceivedInvoice',

  components: { ReceivedInvoiceForm },

  computed: {
    ...mapState('receivedInvoices', [
      'editedReceivedInvoice',
      'receivedInvoiceValidationErrors',
      'receivedInvoiceFilterParams',
    ]),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('receivedInvoices/editReceivedInvoice', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          name: 'receivedInvoices',
          query: store.state.receivedInvoices.receivedInvoiceFilterParams,
        });
      });
  },

  methods: {
    ...mapActions('receivedInvoices', ['updateReceivedInvoice']),
    ...mapMutations('receivedInvoices', ['CLEAR_RECEIVED_INVOICE_VALIDATION_ERRORS']),

    onSave(receivedInvoice) {
      this.updateReceivedInvoice(receivedInvoice).then(() => {
        this.goToReceivedInvoicesPage();
      });
    },

    goToReceivedInvoicesPage() {
      this.$router.push({ name: 'receivedInvoices', query: this.receivedInvoiceFilterParams });
    },
  },
};
</script>
